const quoteBox = Vue.component("quote-box", {
    template: "#quote-box",
    name: "Quote",
    props: {
        id: String,
    },
    data: () => ({
        width: 0,
        height: 0,
        length: 0,
        currentOffset: 0,
        finalOffset: 0,
        visible: false,
    }),
    computed: {
        idString() {
            return "quote-" + this.id;
        },
        strokeStyles() {
            return {
                "stroke-dasharray": this.length,
                "stroke-dashoffset": this.visible
                    ? this.finalOffset
                    : this.currentOffset,
            };
        },
        arrowStyles() {
            return {
                transform: "translateX(" + (this.width / 2 - 8.5) + "px)",
            };
        },
    },
    methods: {
        adjustSVG() {
            const container = this.$refs.svg.parentNode;
            const style = window.getComputedStyle(container, null);

            const paddingX =
                parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
            const paddingY =
                parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

            this.width = container.clientWidth - paddingX;
            this.height = container.clientHeight - paddingY;

            setTimeout(() => {
                const rect = this.$refs.rect;
                this.length =
                    rect.width.baseVal.value * 2 +
                    rect.height.baseVal.value * 2;
                this.currentOffset = this.length;
                this.finalOffset = this.height + 25;

                setTimeout(() => {
                    if (!this.visible && checkVisible(this.$refs.svg))
                        this.adjustSVG();
                    this.visible = this.visible || checkVisible(this.$refs.svg);
                }, 1);
            }, 50);
        },
    },
    mounted() {
        this.adjustSVG();

        window.addEventListener("resize", () => this.adjustSVG());
        window.addEventListener("scroll", () => this.adjustSVG());
        window.addEventListener("keydown", () => this.adjustSVG());
    },
});
