const wysiwygBricks = document.querySelectorAll(".wysiwyg.brick-area");

wysiwygBricks.forEach((brick) => {
    new Vue({
        el: "#" + brick.id,
        name: "WYSIWYG",
        components: {
            quoteBox,
        },
    });
});
