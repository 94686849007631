$(document).ready(function () {
    // Handle jump link nav sticking
    if ($(".poc-sticky-nav").length > 0 && !$(".poc-sticky-nav").hasClass("stickyNavEditmode")) {
        function frames() {
            if (checkBarPosition($('.poc-sticky-nav'))) {
                $('.poc-sticky-nav-container').height($('.poc-sticky-nav').outerHeight());
                $('.poc-sticky-nav').css('position', 'fixed').css('top', $("header.scroll").height()).css('left', '0');
            } else {
                $(".poc-sticky-nav").css('position', 'static');
            }

            //checkIdPosition()

            requestAnimationFrame(frames);
        }
        requestAnimationFrame(frames);
    }

    function checkBarPosition(elm) {
        var pos = $(elm)[0].getBoundingClientRect().top
        if ($(".poc-sticky-nav-container")[0].getBoundingClientRect().top <= $("header.scroll").height()) {
            return true;
        } else if (pos >= $("header.scroll").height()) {
            return false;
        } else {
            return false;
        }
    }

})