/**
 * Vertical Timeline handler
 */

document.addEventListener('DOMContentLoaded', function () {
	// get all timeline instances
	var timelineBricks = document.querySelectorAll('.vertical-timeline-container');

	if (timelineBricks.length) {
		timelineBricks.forEach(function (brick) {
			window.addEventListener('scroll', function () {
				// get the scrolling vertical line
				var scrollLine = brick.querySelector('.scrolling-vertical-line');
				var itemsContainer = brick.querySelector('.timeline-items-container');
				var brickHeight = itemsContainer.offsetHeight;

				// math stuff
				var middleScreen = Math.floor(window.innerHeight / 2);
				var lineRect = scrollLine.getBoundingClientRect();
				var isVisible = window.innerHeight - lineRect.top > 0;

				// timeline indicators
				var allIndicators = brick.querySelectorAll('.timeline-indicator-img');

				// new height of line
				var newHeight = 0;

				// if user scrolls down, grow line down to middle of screen
				if (
					isVisible &&
					lineRect.bottom < middleScreen
				) {
					newHeight = scrollLine.offsetHeight + Math.floor(middleScreen - lineRect.bottom);

					// if we reached the end of the brick, set it to the bricks height
					if (newHeight < brickHeight - 50) {
						scrollLine.style.height = newHeight + 'px';
					} else {
						scrollLine.style.height = brickHeight - 50 + 'px';
					}
				}

				// if user scrolls up, grow line back up to middle of screen
				if (
					isVisible &&
					lineRect.bottom > middleScreen
				) {
					newHeight = scrollLine.offsetHeight - Math.floor(lineRect.bottom - middleScreen);

					// if we are at the top of the brick, set height to 0
					if (newHeight > 0) {
						scrollLine.style.height = newHeight + 'px';
					} else {
						scrollLine.style.height = '0px';
					}
				}

				// handle idicator colors
				allIndicators.forEach(function (indicator) {
					var indicatorBottom = indicator.getBoundingClientRect().bottom;

					if (middleScreen > indicatorBottom) {
						indicator.setAttribute('src', '/static/icons/timeline-indicator-blue.svg');
					} else {
						indicator.setAttribute('src', '/static/icons/timeline-indicator-yellow.svg');
					}
				});
			});
		});
	}
});