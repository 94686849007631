const maps = document.querySelectorAll(".country-map");

maps.forEach((map) => {
    if (!map.id) return;

    new Vue({
        name: "CountryMap",
        el: "#" + map.id,
        delimiters: ["[[", "]]"],
        data: {
            map: null,
            mapBounds: [
                [-168.5, 84],
                [190, -58],
            ],
            popup: null,
            currentCountry: null,
            hoverStateId: null,
            countriesListFilter: [
                'in',
                'iso_a2',
            ],
            countries: [],
            selectedCountry: ''
        },
        methods: {
            addLayers() {
                this.map.addSource("countriesfill", {
                    type: "geojson",
                    data:
                        "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_countries.geojson",
                    generateId: true,
                });

                this.map.addLayer({
                    id: "other-countries-uberfill",
                    type: "fill",
                    source: 'countriesfill',
                    layout: {},
                    paint: {
                        "fill-color": "#007BC2",
                        "fill-opacity": 1,
                    },
                });

                this.map.addLayer({
                    id: "countries-stroke",
                    type: "line",
                    source: "countriesfill",
                    layout: {},
                    paint: {
                        "line-color": "#ffffff",
                        "line-width": 0.5,
                    },
                });

                this.countriesListFilter.push(...this.countries);
                this.map.setFilter('other-countries-uberfill',
                    this.countriesListFilter
                );
                this.setupEventListeners();
            },
            onMouseMove(e) {
                this.map.getCanvas().style.cursor = "pointer";
                if (e.features.length === 0) return;

                if (this.hoveredStateId) {
                    this.map.setFeatureState(
                        { source: "countriesfill", id: this.hoveredStateId },
                        { hover: false }
                    );
                }
                this.hoveredStateId = e.features[0].id;
                this.map.setFeatureState(
                    { source: "countriesfill", id: this.hoveredStateId },
                    { hover: true }
                );

                const country = e.features[0].properties.admin;

                if (this.currentCountry === country) {
                    this.popup.setLngLat(e.lngLat);
                } else {
                    this.currentCountry = country;
                    this.popup
                        .setLngLat(e.lngLat)
                        .setHTML(
                            "<h5 style='margin:0;font-weight:bold'>" +
                            country +
                            "</h5>"
                        )
                        .addTo(this.map);
                }
            },
            onMouseLeave() {
                if (this.hoveredStateId) {
                    this.map.setFeatureState(
                        { source: "countriesfill", id: this.hoveredStateId },
                        { hover: false }
                    );
                }
                this.hoveredStateId = null;

                this.map.getCanvas().style.cursor = "default";

                this.popup.remove();
                this.currentCountry = null;
            },
            setupEventListeners() {
                this.map.on("mousemove", "other-countries-uberfill", (e) =>
                    this.onMouseMove(e)
                );

                this.map.on("mouseleave", "other-countries-uberfill", () =>
                    this.onMouseLeave()
                );

                // this.map.on("click", "other-countries-uberfill", (e) =>
                //     this.countrySelected(e.features[0].properties.admin)
                // );

            },
            disableInteractions() {
                this.map.fitBounds(this.mapBounds);
                this.map.scrollZoom.disable();
                this.map.dragRotate.disable();
                this.map.dragPan.disable();
                this.map.touchZoomRotate.disableRotation();
                this.map.doubleClickZoom.disable();
                this.map.boxZoom.disable();
                this.map.keyboard.disable();
                this.map.getCanvas().style.cursor = "default";

                window.addEventListener("resize", () => {
                    this.map.fitBounds(this.mapBounds);
                });
            },
            decodeHtml(html) {
                let txt = document.createElement("textarea");
                txt.innerHTML = html;
                return txt.value;
            }


        },
        mounted() {

            this.countries = JSON.parse(this.decodeHtml(countryMapCountries));
            if (Array.isArray(this.countries)) {
                this.countries = this.countries.map(item => item.trim());
            } else {
                this.countries = [];
            }
            mapboxgl.accessToken =
                "pk.eyJ1IjoibHVpc2F1Z3VzdG8iLCJhIjoiY2tiOHNveGhnMDFmcDJ3bW5zdjd5ZmY5aiJ9.ARCVzhchmqMNwcEuxUhCZA";
            this.map = new mapboxgl.Map({
                container: map.querySelector(".mapgl").id,
                style: "mapbox://styles/luisaugusto/ckigv67100o601apm5mutjzt5",
                zoom: 3,
                attributionControl: false,
            });

            this.disableInteractions();

            this.map.on("load", () => {
                this.addLayers();
            });

            this.popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
            });
        },
    });
});
