const maps = document.querySelectorAll(".support-map");

maps.forEach((map) => {
    if (!map.id) return;

    new Vue({
        name: "Map",
        el: "#" + map.id,
        delimiters: ["[[", "]]"],
        data: {
            map: null,
            mapBounds: [
                [-168.5, 84],
                [190, -58],
            ],
            popup: null,
            currentCountry: null,
            hoverStateId: null,
            USCountries: ["United States of America"],
            ENCountries: [
                "Italy",
                "Russia",
                "France",
                "Portugal",
                "Spain",
                "Poland",
                "Denmark",
                "United Kingdom",
                "Germany",
                "Japan",
                "China",
            ],
            selectedCountry: ''
        },
        computed: {
            countries() {
                return [...this.USCountries, ...this.ENCountries];
            },
        },
        methods: {
            countrySelected(selectedCountry = this.selectedCountry) {

                var url = 'support';
                if (supportMapMode == 'adf') {
                    url = 'support/assay-definition-import';
                }

                switch (selectedCountry) {
                    case 'United States of America':
                        window.open("/en_US/" + url, "_self");
                        break;

                    case 'Italy':
                        window.open("/it/" + url, "_self");
                        break;

                    case 'Russia':
                        window.open("/ru/" + url, "_self");
                        break;

                    case 'France':
                        window.open("/fr/" + url, "_self");
                        break;

                    case 'Portugal':
                        window.open("/pt/" + url, "_self");
                        break;

                    case 'Spain':
                        window.open("/es/" + url, "_self");
                        break;

                    case 'Poland':
                        window.open("/pl/" + url, "_self");
                        break;

                    case 'Germany':
                        window.open("/de/" + url, "_self");
                        break;

                    case 'United Kingdom':
                        window.open("/en/" + url, "_self");
                        break;

                    case 'Japan':
                        window.open("https://www.beckmancoulter.co.jp");
                        break;

                    case 'China':
                       // window.open("https://www.cepheidchina.com/cn/support-cn");
                        window.open("https://www.cepheidchina.com");
                        break;

                    default:
                        window.open("/en/" + url, "_self")
                }


            },
            addLayers() {
                this.map.addSource("countries", {
                    type: "geojson",
                    data:
                        "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_countries.geojson",
                    generateId: true,
                });

                this.map.addLayer({
                    id: "other-countries-fill",
                    type: "fill",
                    source: "countries",
                    layout: {},
                    paint: {
                        "fill-color": "#007BC2",
                        "fill-opacity": [
                            "case",
                            ["boolean", ["feature-state", "hover"], false],
                            1,
                            0,
                        ],
                    },
                });

                this.map.addLayer({
                    id: "countries-stroke",
                    type: "line",
                    source: "countries",
                    layout: {},
                    paint: {
                        "line-color": "#ffffff",
                        "line-width": 0.5,
                    },
                });

                this.setupEventListeners();
            },
            onMouseMove(e) {
                this.map.getCanvas().style.cursor = "pointer";
                if (e.features.length === 0) return;

                if (this.hoveredStateId) {
                    this.map.setFeatureState(
                        { source: "countries", id: this.hoveredStateId },
                        { hover: false }
                    );
                }
                this.hoveredStateId = e.features[0].id;
                this.map.setFeatureState(
                    { source: "countries", id: this.hoveredStateId },
                    { hover: true }
                );

                const country = e.features[0].properties.admin;

                if (this.currentCountry === country) {
                    this.popup.setLngLat(e.lngLat);
                } else {
                    this.currentCountry = country;
                    this.popup
                        .setLngLat(e.lngLat)
                        .setHTML(
                            "<h5 style='margin:0;font-weight:bold'>" +
                            country +
                            "</h5>"
                        )
                        .addTo(this.map);
                }
            },
            onMouseLeave() {
                if (this.hoveredStateId) {
                    this.map.setFeatureState(
                        { source: "countries", id: this.hoveredStateId },
                        { hover: false }
                    );
                }
                this.hoveredStateId = null;

                this.map.getCanvas().style.cursor = "default";

                this.popup.remove();
                this.currentCountry = null;
            },
            setupEventListeners() {
                this.map.on("mousemove", "other-countries-fill", (e) =>
                    this.onMouseMove(e)
                );

                this.map.on("mouseleave", "other-countries-fill", () =>
                    this.onMouseLeave()
                );

                this.map.on("click", "other-countries-fill", (e) =>
                    this.countrySelected(e.features[0].properties.admin)
                );

            },
            disableInteractions() {
                this.map.fitBounds(this.mapBounds);
                this.map.scrollZoom.disable();
                this.map.dragRotate.disable();
                this.map.dragPan.disable();
                this.map.touchZoomRotate.disableRotation();
                this.map.doubleClickZoom.disable();
                this.map.boxZoom.disable();
                this.map.keyboard.disable();
                this.map.getCanvas().style.cursor = "default";

                window.addEventListener("resize", () => {
                    this.map.fitBounds(this.mapBounds);
                });
            },
        },
        mounted() {
            mapboxgl.accessToken =
                "pk.eyJ1IjoibHVpc2F1Z3VzdG8iLCJhIjoiY2tiOHNveGhnMDFmcDJ3bW5zdjd5ZmY5aiJ9.ARCVzhchmqMNwcEuxUhCZA";
            this.map = new mapboxgl.Map({
                container: map.querySelector(".mapgl").id,
                style: "mapbox://styles/luisaugusto/ckigv67100o601apm5mutjzt5",
                zoom: 3,
                attributionControl: false,
            });

            this.disableInteractions();

            this.map.on("load", () => {
                this.addLayers();
            });

            this.popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
            });
        },
    });
});
