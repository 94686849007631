const carousel = document.querySelectorAll(".carousel");

carousel.forEach((brick) => {
    if (
        (brick.classList.contains("quotes") ||
        brick.classList.contains("greybackground")) &&
        brick.classList.contains("edit")
    )
        return;

    if (
        brick.classList.contains("featurette") &&
        brick.classList.contains("edit")
    )
        return;

    new Vue({
        el: "#" + brick.id,
        name: "Carousel",
        delimiters: ["${", "}$"],
        data: {
            colCount: 1,
            storyCount: 0,
            activeRow: 1,
            prevRow: 0,
            touchX: 0,
        },
        components: {
            quoteBox,
        },
        computed: {
            rowCount() {
                return Math.ceil(this.storyCount / this.colCount);
            },
        },
        methods: {
            countCols() {
                const offsets = [];
                const stories = this.$refs.carousel.querySelectorAll(
                    '.carousel-layout > .row > div[class*="col"]'
                );

                this.storyCount = stories.length;

                Array.from(stories).some((story) => {
                    const offset = story.getBoundingClientRect().left;
                    const repeatOffset = offsets.some((o) => o >= offset - 30); //30px for the left offset of the transition animation

                    if (!repeatOffset) offsets.push(offset);

                    return repeatOffset;
                });

                this.colCount = offsets.length;

                if (this.activeRow > this.rowCount) {
                    this.activeRow = this.rowCount;
                }
            },
            changeRows(row) {
                if (row === this.activeRow) return;

                this.prevRow = this.activeRow;
                this.activeRow = row;
            },
            touchStart(e) {
                this.touchX = e.changedTouches[0].screenX;
            },
            touchEnd(e) {
                const touchEnd = e.changedTouches[0].screenX;
                const diff = touchEnd - this.touchX;

                if (diff < -40) {
                    if (this.activeRow < this.rowCount) {
                        this.changeRows(this.activeRow + 1);
                    } else {
                        this.changeRows(1);
                    }
                }

                if (diff > 40) {
                    if (this.activeRow > 1) {
                        this.changeRows(this.activeRow - 1);
                    } else {
                        this.changeRows(this.rowCount);
                    }
                }

                this.touchX = 0;
            },
        },
        mounted() {
            this.countCols();
            window.addEventListener("resize", () => this.countCols());
        },
    });
});
