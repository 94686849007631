new Vue({
    el: "#nav",
    name: "Navigation",
    data: {
        activeNav: 0, //The current subnav that is being displayed using hover or tap
        hovering: false, //Is the user currently hovering over or tapping a top nav item
        activePage: null, //The current page that the user is on
        hamburgerIcon: "", //SVG pulled using fetch in mounted method
        closeIcon: "", //SVG pulled using fetch in mounted method
        mobileOpen: false, //Is the mobile menu open
        mobileBreakpoint: 991, //Breakpoint to start handling mobile logic
        currentMobileView: 0, //Parent page ID for the mobile nav you are looking at
        defaultMobileView: 0, //Get the parent ID of the current page
        prevArray: [], //An array of parent page IDs for the current page or mobile navigation traverse
        defaultArray: [], //An array of parent page IDs for the current page
        scrollable: false, //Is the mobile menu long enough to enable scrolling
        scrollTop: 0, //The scroll position of the mobile nav
    },
    watch: {
        currentMobileView() {
            this.checkScrollable();
        },
    },
    methods: {
        dblclick(el) {
            if (Modernizr.touchevents) {
                window.open(el.href, el.target || "_self");
            }
        },
        click(el, topNav = true, children = true) {
            const id = Number(el.dataset.ref);

            if (window.innerWidth <= this.mobileBreakpoint) {
                if (!children) {
                    window.open(el.href, el.target || "_self");
                } else {
                    this.prevArray.push(this.currentMobileView);
                    this.currentMobileView = id;
                    this.scrollTop = 0;
                }

                return;
            }

            if (Modernizr.touchevents && topNav) {
                if (this.activeNav === id) {
                    this.mouseout(id);
                } else {
                    this.mouseover(id);
                }
            } else {
                window.open(el.href, el.target || "_self");
            }
        },
        mouseover(id) {
            this.activeNav = id;
            this.hovering = true;
        },
        mouseout(id) {
            this.hovering = false;

            if (Modernizr.touchevents) {
                this.activeNav = 0;
            } else {
                setTimeout(() => {
                    if (this.activeNav === id && !this.hovering)
                        this.activeNav = 0;
                }, 300);
            }
        },
        currentPage(id) {
            return this.$refs["page-" + id] === this.activePage;
        },
        toggleMobile() {
            this.mobileOpen = !this.mobileOpen;
            $("body").toggleClass("mobileOpen");

            this.currentMobileView = this.defaultMobileView;
            this.prevArray = [...this.defaultArray];
            this.checkScrollable();
        },
        back() {
            this.currentMobileView = this.prevArray.pop();
            this.scrollTop = 0;
        },
        checkScrollable() {
            setTimeout(() => {
                const activeView = document.querySelector(".active-view");
                if (activeView)
                    this.scrollable =
                        activeView.scrollHeight > activeView.clientHeight;
            }, 500);
        },
        scroll: function scroll(event) {
            this.scrollTop = event.target.scrollTop;
        },
    },
    mounted() {
        fetch("/static/icons/hamburger.svg")
            .then((data) => data.text())
            .then((svg) => (this.hamburgerIcon = svg));

        fetch("/static/icons/close.svg")
            .then((data) => data.text())
            .then((svg) => (this.closeIcon = svg));

        const activePages = document.querySelectorAll("#nav .page-path");
        const activePage = activePages[activePages.length - 1];
        this.activePage = activePage;

        this.defaultMobileView = Number(this.$refs.nav.dataset.defaultView);
        this.currentMobileView = this.defaultMobileView;

        const ref = "anchor-" + this.defaultMobileView;
        this.defaultArray = this.$refs.hasOwnProperty(ref)
            ? this.$refs[ref].dataset.parents.split(",").map(Number)
            : [];
        this.prevArray = this.defaultArray;

        window.addEventListener("resize", () => {
            this.checkScrollable();
        });
    },
});
