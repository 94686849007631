const accordions = document.querySelectorAll(".accordion.brick-area");

accordions.forEach((accordion) => {
    if (accordion.classList.contains('edit') || accordion.classList.contains('support')) return;
    new Vue({
        el: "#" + accordion.id,
        name: "Accordion",
        data: {
            openItems: [],
        },
        methods: {
            open(item) {
                const mainContainer =
                    arguments.length <= 1 || arguments[1] === undefined
                        ? false
                        : arguments[1];

                if (mainContainer && this.isOpen(item)) {
                    return;
                }

                const index = this.openItems.indexOf(item);

                if (index > -1) {
                    this.openItems.splice(index, 1);
                } else {
                    this.openItems.push(item);
                }
            },
            isOpen(item) {
                return this.openItems.indexOf(item) > -1;
            },
        },
    });
});

// leadership template

let leaderDescription;
let heightOfleaderDescription;
let selectedElement;
let clicked = false;

// handle grayscale on hover
$(".leadership-block__item").hover(function () {
    if (clicked == false) {
        let remainingImages = $('.leadership-block__item').not(this).find('.leadership-block_image');
        remainingImages.children('img').css('opacity', '0.5');
    }
},
    function () {
        if (clicked == false) {
            let remainingImages = $('.leadership-block__item').not(this).find('.leadership-block_image');
            remainingImages.children('img').css('opacity', '1');
        }
    });

$('.leadership-block__item').click(function (event) {

    if (!event.detail || event.detail == 1) {//activate on first click only to avoid hiding again on multiple clicks
        // set variables
        selectedElement = $(this);
        let items = $('.leadership-block__parent_container').children();
        let itemChildren = $(items).children('.leadership-block__item');
        let remainingImages = $('.leadership-block__item').not(this).find('.leadership-block_image');

        // grab the description container of the element clicked
        leaderDescription = selectedElement.children('.leadership-block__profile_content_not-edit');
        selectedElement.addClass('activate');

        // offset the height of the current description container
        heightOfleaderDescription = leaderDescription.outerHeight() + 54;
        selectedElement.animate({ marginBottom: heightOfleaderDescription + 'px' }, 300);

        // when the window is resized set styles
        $(window).on('resize', function () {
            heightOfleaderDescription = leaderDescription.height() + 54;
            heightOfleaderDescription = 0;
            if (!selectedElement.hasClass('activate')) {
                selectedElement.css('margin-bottom', '0px');
            } else {
                if ($(window).width() < 625) {
                    remainingImages.children('img').css('opacity', '1');
                } else {
                    remainingImages.children('img').css('opacity', '0.5');
                }
            }
        });

        // toggling and setting styles based on if its open or not
        $(leaderDescription).slideToggle(300, function () {
            if (leaderDescription.is(':hidden')) {
                remainingImages.children('img').css('opacity', '1');
                selectedElement.removeClass('activate');
                selectedElement.find('.leadership-block__yellow_arrow').css('display', 'none');
                selectedElement.stop().animate({ marginBottom: '0' }, 200);
                // if accordion is closed after selecting several, undo styles
                if (!selectedElement.hasClass('activate')) {
                    selectedElement.find('.leadership-block_image').children('img').css('opacity', '1');
                    selectedElement.find('.leadership-block__yellow_arrow').css('display', 'none');
                }
                clicked = false;
            }
            else {
                // off set the scroll when open
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(selectedElement).offset().top - 135
                }, 1000);
                remainingImages.children('img').css('opacity', '0.5');
                selectedElement.find('.leadership-block__yellow_arrow').css('display', 'inline');
                $(window).on('resize', function () {
                    heightOfleaderDescription = leaderDescription.outerHeight() + 54;
                    selectedElement.css('margin-bottom', heightOfleaderDescription + 'px');
                });
                clicked = true;
            }
        });

        // remove open styles and close accordions when a new one is clicked
        for (let i = 0; i < itemChildren.prevObject.length; i++) {
            if (itemChildren.prevObject[i].classList.contains('activate') && $(itemChildren.prevObject[i]).children('.leadership-block__profile_content_not-edit').html() != leaderDescription.html()) {
                $(itemChildren.prevObject[i]).removeClass('activate');
                $(itemChildren.prevObject[i]).animate({ marginBottom: '0' }, 100);
                $(itemChildren.prevObject[i]).find('.leadership-block__yellow_arrow').css('display', 'none');
                $(itemChildren.prevObject[i]).children('.leadership-block__profile_content_not-edit').hide();
            }
        }
    }
})

