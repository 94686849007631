if (document.querySelector("#contact-list")) {
    new Vue({
        name: "Contact List",
        el: "#contact-list",
        delimiters: ["[[", "]]"],
        data: {
            countries: [],
            selectedCountry: "",
            selectedRegion: "",
            selectedType: "",
        },
        watch: {
            selectedRegion() {
                this.selectedCountry = "";
                this.selectedType = "";
            },
            selectedCountry() {
                this.selectedType = "";
            },
        },
        methods: {
            checkForEmptyContact(c) {
                return (
                    !c.contactAddress &&
                    !c.contactName &&
                    !c.distributorLink &&
                    !c.email &&
                    !c.officeHours &&
                    !c.website &&
                    c.contactNumbers?.every((number) => !number.number)
                );
            },
        },
        computed: {
            regions() {
                const allRegions = this.countries.flatMap(({ regions }) =>
                    regions.map(({ regionName }) => regionName)
                );
                return Array.from(new Set(allRegions)).sort();
            },
            filteredCountries() {
                return this.countries.filter(
                    (country) =>
                        country.contacts &&
                        country.regions.find(
                            ({ regionName }) =>
                                regionName === this.selectedRegion
                        )
                );
            },
            selectedCountryData() {
                const country = this.countries.find(
                    ({ id }) => id === this.selectedCountry
                );

                if (country) {
                    country.contacts = country.contacts.filter(
                        (c) => !this.checkForEmptyContact(c)
                    );
                }
                return country;
            },
            contactTypes() {
                const getTypes = (contacts) => {
                    return contacts
                        .filter((c) => !this.checkForEmptyContact(c))
                        .map(({ contactType }) => contactType);
                };

                let allTypes = [];
                if (this.selectedCountryData) {
                    allTypes = getTypes(this.selectedCountryData.contacts);
                } else {
                    allTypes = this.filteredCountries.flatMap(({ contacts }) =>
                        getTypes(contacts)
                    );
                }

                return Array.from(new Set(allTypes)).sort();
            },
            filteredContacts() {
                const filter = (country) => {
                    return this.contactTypes.reduce((acc, type) => {
                        if (this.selectedType && type !== this.selectedType)
                            return acc;

                        const contacts = country.contacts.filter(
                            ({ contactType }) => contactType === type
                        );

                        if (
                            contacts.every((contact) =>
                                this.checkForEmptyContact(contact)
                            )
                        )
                            return acc;

                        if (contacts.length > 0) {
                            return [
                                ...acc,
                                {
                                    type,
                                    country: country.countryName,
                                    contacts: contacts,
                                },
                            ];
                        } else {
                            return acc;
                        }
                    }, []);
                };

                if (this.selectedCountryData) {
                    return filter(this.selectedCountryData);
                } else {
                    return this.filteredCountries.flatMap((country) =>
                        filter(country)
                    );
                }
            },
        },
        mounted() {
            const pageUrl = this.$refs.container.dataset.locale;
            let locale = pageUrl.split("/")[1];
            locale = locale === "en_US" ? "us" : locale;

            axios({
                url:
                    "/cepheid-webservices/support-contact?apikey=1d80017695dc32a2fefa373642da09eb",
                method: "post",
                data: {
                    query: `{
  getCountryListing(sortBy: "countryName", defaultLanguage: "${locale}") {
    edges {
      node {
        id,
        countryName,
        contacts {
          ...on object_Contact {
            id,
            contactName,
            contactType,
            contactAddress,
            contactNumbers {
            \t...on fieldcollection_ContactNumbers {
                number,
                numberType
              }
            },
            distributorLink {
              path
            },
            website {
              path
            },
            email,
            officeHours
          }
        },
        regions {
          ...on object_CoverageRegion {
            regionName
          }
        }
      }
    }
  }
}`,
                },
            })
                .then((result) => {
                    this.countries = result.data.data.getCountryListing.edges.map(
                        ({ node }) => node
                    );

                    if (window.location.href.includes('en-us') || window.location.href.includes('en_US')) {
                        this.selectedRegion = 'Americas'
                        setTimeout(() => {
                            this.selectedCountry = '2020'
                        }, 50)
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    });
}
