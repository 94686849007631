$(document).ready(function () {
    // Handle jump link nav sticking
    if ($(".sticky-nav").length > 0 && !$(".sticky-nav").hasClass("stickyNavEditmode")) {
        function frames() {
            if (checkBarPosition($('.sticky-nav'))) {
                $('.sticky-nav-container').height($('.sticky-nav').outerHeight());
                $('.sticky-nav').css('position', 'fixed').css('top', $("header.scroll").height()).css('left', '0');
            } else {
                $(".sticky-nav").css('position', 'static');
            }

            checkIdPosition()

            requestAnimationFrame(frames);
        }
        requestAnimationFrame(frames);
    }

    function checkBarPosition(elm) {
        var pos = $(elm)[0].getBoundingClientRect().top
        if ($(".sticky-nav-container")[0].getBoundingClientRect().top <= $("header.scroll").height()) {
            return true;
        } else if (pos >= $("header.scroll").height()) {
            return false;
        } else {
            return false;
        }
    }

    function checkIdPosition() {
        $(".jump-link").each(function () {
            var anchor = $("#" + $(this).attr("data-anchor-link"))[0];
            if (anchor) {
                var anchorTopEdge = anchor.getBoundingClientRect().top;
                var anchorBottomEdge = anchor.getBoundingClientRect().top + $(anchor).outerHeight();
                var jumpLinksBottomEdge = $(".sticky-nav")[0].getBoundingClientRect().top + $(".sticky-nav").height();
                if (anchorTopEdge <= jumpLinksBottomEdge && anchorBottomEdge > jumpLinksBottomEdge) {
                    $(this).addClass("active");
                } else {
                    $(this).removeClass("active");
                }
            }
        })
    }

    // Handle smooth scroll
    $(document).on('click', '.jump-link', function (event) {
        event.preventDefault();
        var href = $.attr(this, 'href');

        var offsetVal;
        if ($(".sticky-nav").height() > 0) {
            offsetVal = $("header").height() + $(".sticky-nav").height() - 1;
        } else {
            offsetVal = $("header").height() - 1;
        }

        $('html, body').animate({
            scrollTop: $(href).offset().top - offsetVal
        }, "fast", "linear");

        window.location.hash = href;
    });

})