if (document.querySelector("#home-profile-selector")) {
    new Vue({
        el: "#home-profile-selector",
        name: "Home Profile Selector",
        delimiters: ["${", "}"],
        data: { options: homeProfileOptions, visible: false },
        methods: {
            adjustHeader() {
                const profileSelector = this.$refs.profileSelector;

                setTimeout(() => {
                    this.visible =
                        this.visible || checkVisible(profileSelector);
                }, 50);
            },
        },
        mounted() {
            this.options.forEach((option) => {
                fetch(option.icon).then((data) =>
                    data.text().then((svg) => (option.svg = svg))
                );
            });

            this.adjustHeader();

            if (window.innerWidth < 768) this.visible = true;

            window.addEventListener("resize", () => this.adjustHeader());
            window.addEventListener("scroll", () => this.adjustHeader());
        },
    });
}
