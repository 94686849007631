// Doc ready pimcore functions
$(function () {
    // Why are you on IE?
    if (isIEorEDGE()) {
        $("#browserUpdate").removeClass("d-none");
    }

    // gallery carousel: do not auto-start
    $(".gallery").carousel("pause");

    // tabbed slider text
    var clickEvent = false;
    $(".tabbed-slider")
        .on("click", ".nav a", function () {
            clickEvent = true;
            $(".nav li").removeClass("active");
            $(this).parent().addClass("active");
        })
        .on("slid.bs.carousel", function (e) {
            if (!clickEvent) {
                var count = $(".nav").children().length - 1;
                var current = $(".nav li.active");
                current.removeClass("active").next().addClass("active");
                var id = parseInt(current.data("slide-to"));
                if (count == id) {
                    $(".nav li").first().addClass("active");
                }
            }
            clickEvent = false;
        });

    // lightbox (magnific)
    $("a.thumbnail").magnificPopup({
        type: "image",
        gallery: {
            enabled: true,
        },
    });

    // Enable tooltips
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({
        trigger: "hover",
    });

    $(".video-btn:not(.disable-magnific)").magnificPopup({ type: "iframe" });

    $(".impact-story-banner").click(function () {
        const url = $(this).find("a").attr("href");
        const target = $(this).find("a").attr("target");

        window.open(url, target || "_self");
    });

    // Redirect Modal
    $("#redirect-modal").on("show.bs.modal", function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var redirectURL = button.data("href"); // Extract info from data-* attributes
        var siteName = button.text();

        $(this).find("#redirect-site-name").text(siteName);
        $(this).find("#redirect-btn").attr("href", redirectURL);

        // for links we need opened in separate tabs
        if (button.attr('target') === '_blank') {
            $(this).find("#redirect-btn").attr("target", '_blank');
        } else {
            $(this).find("#redirect-btn").removeAttr("target");
        }

        // // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        // var modal = $(this)
        // modal.find('.modal-title').text('New message to ' + recipient)
        // modal.find('.modal-body input').val(recipient)
    });
});

$(".product-sections-nav:not(.support) a, a.ordering-btn, .support-documents-buttons a, .mini-nav a").click(function (e) {
    e.preventDefault();

    const id = $(this).attr("href");
    const offset = $(id).offset().top;

    if ($(this).hasClass("product-sections-nav")) {
        $(".product-sections-nav a").removeClass("active");
        $(this).addClass("active");
    }

    const headerOffset = $(this).hasClass("btn") ? 100 : 166;

    $("html, body").animate(
        {
            scrollTop: offset - headerOffset,
        },
        500
    );
});

// Functions

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    if (!exdays) {
        d.setDate(d.getDate() - 1);
    } else {
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    }
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function checkVisible(elm) {
    const vpH = $(window).height();
    const st = $(window).scrollTop();
    const y = $(elm).offset().top + Math.max(elm.clientHeight / 2, 100);
    const elementHeight = $(elm).height();

    const isVisible = y < vpH + st && y > st - elementHeight;

    const vpW = $(window).width();
    const x = $(elm).offset().left + elm.clientWidth / 2;
    const elementWidth = $(elm).width();

    const isVisibleX = x < vpW && x > -elementWidth;

    if (isVisible && isVisibleX) return true;
    else return false;
}

function isIEorEDGE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf("rv:");
        return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
    // other browser
    return false;
}

if ($(".product-sections:not(.support)").length > 0) {
    checkProductOffsets();

    window.addEventListener("scroll", () => checkProductOffsets());
    window.addEventListener("resize", () => checkProductOffsets());
}

function getProductOffsets() {
    const offsets = [];
    $(".product-sections-nav a").each(function () {
        const id = $(this).attr("href");
        const offset = $(id).offset().top;
        offsets.push(offset);
    });

    return offsets;
}

function checkProductOffsets() {
    const offsets = getProductOffsets();

    offsets.forEach((offset, i) => {
        const currentScroll = $(window).scrollTop() + 168;

        if (currentScroll >= offset) {
            if (currentScroll < offsets[i + 1] || offsets.length - 1 === i) {
                $(".product-sections-nav a").removeClass("active");
                $(".product-sections-nav li:eq(" + i + ") a").addClass(
                    "active"
                );
            }
        }
    });
}

function scrollToId() {
    const text = location.hash.substr(1);

    if (text) {
        setTimeout(() => {
            const offset = $('#' + text).offset().top;
            $("html, body").animate(
                {
                    scrollTop: offset - 166,
                },
                500
            );
        }, 700);
    }
}

const convertImages = (query, callback) => {
    const images = document.querySelectorAll(query);

    images.forEach((image) => {
        fetch(image.src)
            .then((res) => res.text())
            .then((data) => {
                const parser = new DOMParser();
                const svg = parser
                    .parseFromString(data, "image/svg+xml")
                    .querySelector("svg");

                const [a, b, c, d] = svg
                    .getAttribute("viewBox")
                    .split(" ")
                    .map(Number);
                svg.setAttribute("viewBox", `${a} ${b} ${c + 2} ${d}`);

                if (image.id) svg.id = image.id;
                if (image.className) svg.classList = image.classList;

                image.parentNode.replaceChild(svg, image);
            })
            .then(callback)
            .catch((error) => console.error(error));
    });
};

$(document).ready(function () {
    convertImages(".wysiwyg img.svg, div.svg img", () => {
        $("svg text").each(function () {
            const size =
                $(this).attr("font-weight") == "bold"
                    ? $(this).attr("font-size")
                    : parseFloat($(this).attr("font-size")) - 0.5 + "px";

            $(this).css({
                color: $(this).attr("fill"),
                "font-size": size,
                "font-weight": $(this).attr("font-weight"),
                "letter-spacing": $(this).attr("letter-spacing"),
            });
        });
    });

    $(".area-resources").click("a.preview-item", function (e) {
        if ($(this).hasClass("edit")) {
            e.preventDefault();
        }
    });

    if (!$("body").hasClass("edit-mode")) {
        $(
            "h2.center:contains('®'), h2:contains('®'), h4:contains('®'), p.clamped-6:contains('®'), p.clamped:contains('®') "
        ).html(function (_, html) {
            return html.replace(/(®)/g, "<sup>$1</sup>");
        });

        $(
            'h2:contains("C. difficile/Epi"), a:contains("C. difficile/Epi"), .breadcrumbs:contains("C. difficile/Epi"), p:contains("C. difficile/Epi")'
        ).html(function (_, html) {
            return html.replace(/(C. difficile\/Epi)/g, "<em>$1</em>");
        });

        $(
            'h2:contains("difficile/Epi"), a:contains("difficile/Epi"), .breadcrumbs:contains("difficile/Epi"), p:contains("difficile/Epi")'
        ).html(function (_, html) {
            return html.replace(/(difficile\/Epi)/g, "<em>$1</em>");
        });

        $(
            'h2:contains("C. difficile"), a:contains("C. difficile"), .breadcrumbs:contains("C. difficile"), p:contains("C. difficile")'
        ).html(function (_, html) {
            return html.replace(/(C. difficile)/g, "<em>$1</em>");
        });

        $(
            'h2:contains("Clostridium"), a:contains("Clostridium"), .breadcrumbs:contains("Clostridium"), p:contains("Clostridium")'
        ).html(function (_, html) {
            return html.replace(/(Clostridium)/g, "<em>$1</em>");
        });

        $(
            'h2:contains("difficile"), a:contains("difficile"), .breadcrumbs:contains("difficile"), p:contains("difficile")'
        ).html(function (_, html) {
            return html.replace(/(difficile)/g, "<em>$1</em>");
        });

        $(
            'h2:contains("aureus"), a:contains("aureus"), p:contains("aureus")'
        ).html(function (_, html) {
            return html.replace(/(aureus)/g, "<em>$1</em>");
        });

        $(
            'h2:contains("vanA"), a:contains("vanA"), p:contains("vanA")').html(
                function (_, html) {
                    return html.replace(/(vanA)/g, "<em>vanA</em>");
                }
            );

        $(
            'h2:contains("vanB"), a:contains("vanB"), p:contains("vanB")').html(
                function (_, html) {
                    return html.replace(/(vanB)/g, "<em>vanB</em>");
                }
            );

        $(
            'h2:contains("RSV plus"), h4:contains("RSV plus"), a:contains("RSV plus"), a:contains("CoV 2 plus"), a:contains("CoV-2 plus"), .breadcrumbs:contains("RSV plus"), p:contains("RSV plus")'
        ).html(function (_, html) {
            return html.replace(/(plus)/g, "<b><em>plus</em></b>");
        });

        $('h1:contains("plus")').html(function (_, html) {
            return html.replace(/(plus)/g, "<em>plus</em>");
        });

        $(
            'strong:contains("C. Diff")').html(function (_, html) {
                return html.replace(/(C. Diff)/g, "<em>C. Diff</em>");
            });

        // fix the links
        $("a").each(function () {
            const href = $(this).attr("href");
            if (href && href.indexOf("<em>difficile</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>difficile<\/em>)/g, "difficile"));
            }
            if (href && href.indexOf("<em>vanA</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>vanA<\/em>)/g, "vanA"));
            }
            if (href && href.indexOf("<em>vanB</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>vanB<\/em>)/g, "vanB"));
            }
            if (href && href.indexOf("<em>aureus</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>aureus<\/em>)/g, "aureus"));
            }
            if (href && href.indexOf("<em>C. difficile\/Epi</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>C. difficile\/Epi<\/em>)/g, "C. difficile\/Epi"));
            }
            if (href && href.indexOf("<em>C. difficile</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>C. difficile<\/em>)/g, "C. difficile"));
            }
            if (href && href.indexOf("<em>Clostridium</em>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<em>Clostridium<\/em>)/g, "Clostridium"));
            }
            if (href && href.indexOf("<b><em>plus</em></b>") > -1) {
                $(this).attr("href", $(this).attr("href").replace(/(<b><em>plus<\/em><\/b>)/g, "plus"));
            }
        });
    }

    //hide form_builder input label
    if ($( ".communication_preferences" ).length > 0) {
        $( ".communication_preferences" ).prev('.col-form-label').css( "display", "none" );
    }

    $(".template-object-cards").each(function () {
        $(this).addClass("adjusted");
    });

    scrollToId(); // Scroll to ID if it's present in the URL on page load
});

$(window).scroll(function (e) {
    if ($(window).scrollTop() > $(window).height()) {
        $(".back-to-top").addClass("display");
    } else {
        $(".back-to-top").removeClass("display");
    }
});

$(".back-to-top").click(function () {
    $("html, body").animate(
        {
            scrollTop: 0,
        },
        500
    );
});

if($('.mdsRegionalIFU')) {
    document.addEventListener("DOMContentLoaded", function(){

        function toggleSlider() {
            let slide = this.querySelector('.slide');
            let icon = this.querySelector('.slider-icon');

            if (slide.classList.contains('slide-up')) {
               slide.classList.remove('slide-up');
                slide.style.height = 0;
            } else {
                slide.classList.add('slide-up');
                slide.style.height = slide.scrollHeight + 'px';
            }

            $(icon).toggleClass('active');
        }

        let sliders = document.querySelectorAll('.slider');

        sliders.forEach(slider => {
            slider.addEventListener('click', toggleSlider);
        })
    });
}

//style for upload input
if($(".form-group .innovation-upload-input").length) {
    let uploadInput = document.querySelector(".form-group .innovation-upload-input");
    uploadInput.style.height = "200px";
    uploadInput.style.padding = 0;
}

//self service support - replace registered trademark with superscript
$('#self-service-support-form .selectpicker').on('show.bs.select', function (e, clickedIndex, newValue, oldValue) {

    $('.dropdown-item .text').each(function(i, elem) {
        if (!elem.innerHTML.toLowerCase().includes('<sup>')) {
            //replace registered trademark with superscript
            $(elem).html(function(i, html) {
                return html.replace(/\u00ae/g, "<sup>&reg;</sup>");
            });
        }
    });
});


$('#self-service-support-form .selectpicker').on('changed.bs.select', function (e) {

    let selectedOption = $('.selectpicker option:selected').text();
    if (!selectedOption.toLowerCase().includes('<sup>')) {
        //replace registered trademark with superscript
        $('.filter-option-inner-inner').html(selectedOption.replace(/\u00ae/g, "<sup>&reg;</sup>"));
    }

});
