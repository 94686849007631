const timelines = document.querySelectorAll(".timeline-container");

timelines.forEach((brick) => {
    if (brick.classList.contains("edit")) return;

    new Vue({
        el: "#" + brick.id,
        delimiters: ["${", "}$"],
        data: {
            timelineSVG: "",
            arrowSVG: "",
            stopwatchSVG: "",
            timelineVisible: false,
            itemVisibility: {
                "item-arrow": false,
            },
            visibilityQueue: 0,
            progressBar: 0,
            locked: false,
            scrollLength: 0,
            containerHeight: 0,
        },
        methods: {
            timelineVisibility() {
                this.scrollLength =
                    this.$refs.items.scrollWidth - this.$refs.items.clientWidth;
                this.containerHeight =
                    this.scrollLength + this.$refs.timelineSection.clientHeight;
                const currentVisibility = this.timelineVisible;

                this.timelineVisible =
                    this.timelineVisible ||
                    checkVisible(this.$refs.timelineSection);
                if (this.timelineVisible === currentVisibility) return;

                setTimeout(() => this.itemsVisibility(), 500);
            },
            itemsVisibility() {
                this.progressBar =
                    (this.$refs.items.scrollLeft / this.scrollLength) * 100;

                for (const item in this.itemVisibility) {
                    const visible = checkVisible(this.$refs[item]);

                    if (visible && !this.itemVisibility[item]) {
                        this.visibilityQueue++;

                        setTimeout(() => {
                            this.itemVisibility[item] = true;
                        }, this.visibilityQueue * 200);

                        setTimeout(() => {
                            this.visibilityQueue--;
                        }, 50);
                    }
                }
            },
            scrollJack() {
                const scroll = Math.floor(window.scrollY);

                const timelineOffset =
                    this.$refs.timeline.getBoundingClientRect().top + scroll;
                const headerHeight = document.querySelector("header")
                    .clientHeight;
                const trueOffset = Math.floor(timelineOffset - headerHeight);

                if (scroll >= trueOffset || this.progressBar == 100) {
                    this.locked = true;
                }

                if (
                    this.progressBar === 100 &&
                    scroll > trueOffset + this.containerHeight
                ) {
                    this.locked = false;
                }

                if (this.progressBar === 0 && scroll < trueOffset) {
                    this.locked = false;
                }

                if (!this.locked) return;
                this.$refs.items.scroll({ top: 0, left: scroll - trueOffset });
            },
        },
        mounted() {
            brick.querySelectorAll(".item.timepiece").forEach((el, i) => {
                this.itemVisibility["item-" + (i + 1)] = false;
            });

            this.timelineVisibility();

            window.addEventListener("resize", () => this.timelineVisibility());
            window.addEventListener("scroll", () => this.timelineVisibility());
            window.addEventListener("scroll", () => this.scrollJack());
            window.addEventListener("scroll", () => this.itemsVisibility());

            fetch("/static/icons/timeline.svg")
                .then((data) => data.text())
                .then((svg) => (this.timelineSVG = svg));

            fetch("/static/icons/arrow.svg")
                .then((data) => data.text())
                .then((svg) => (this.arrowSVG = svg));

            fetch("/static/icons/stopwatch-timeline.svg")
                .then((data) => data.text())
                .then((svg) => (this.stopwatchSVG = svg));
        },
    });
});
