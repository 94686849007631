function adjustParallax(masthead, img) {
    const height = masthead.clientHeight;

    const scroll = window.scrollY;
    const winHeight = window.innerHeight;

    const offset = masthead.getBoundingClientRect().top + scroll;

    const position = scroll - offset + winHeight;
    const parallax = masthead.dataset.intensity * 10;
    const multiplier = 1 + parallax / 100;
    const heightDiff = Math.max(1, Math.abs(height - winHeight));
    const minSlope = -parallax / -heightDiff;
    const maxSlope = -parallax / (height - height * multiplier);
    const slope = Math.min(minSlope, maxSlope);
    const intercept = -(slope * winHeight);

    const top = slope * position + intercept;

    img.each(function () {
        $(this)[0].style.top = top + "%";
    });
}

function setupParallax(container) {
    if (container.hasClass("parallax")) {
        const node = container[0];
        const img = container.find(".masthead-bg img");

        if (img.length > 0) {
            adjustParallax(node, img);
            container.addClass("adjusted");

            window.addEventListener("scroll", (e) => adjustParallax(node, img));
            window.addEventListener("resize", (e) => adjustParallax(node, img));
        }
    } else {
        container.addClass("adjusted");
    }
}

const parallaxBricks = document.querySelectorAll(".masthead, .featurette");

parallaxBricks.forEach((brick) => {
    if (brick.id) {
        setupParallax($("#" + brick.id));
    }
});
