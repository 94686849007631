const newsEvents = document.querySelectorAll(".news-events.brick-area");

newsEvents.forEach((brick) => {
    var limit = brick.dataset.limit;
    if (limit > 0) {
        new Vue({
            el: "#" + brick.id,
            name: "News & Events",
            delimiters: ["${", "}$"],
            data: {
                feedItems: [],
            },
            mounted() {
                axios.get("/newsfeed/" + limit).then((response) => {
                    if (response.data.success) {
                        this.feedItems = response.data.items;
                    }
                });
            },
        });
    }
});
