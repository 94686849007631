const ctaSectionBricks = document.querySelectorAll(".cta-section.brick-area");

ctaSectionBricks.forEach((brick) => {
    new Vue({
        el: "#" + brick.id,
        name: "CTA Section",
        components: {
            quoteBox,
        },
    });
});
