new Vue({
    el: "#siteSearch",
    name: "Global Search Bar",
    data: {
        inputOpen: false,
        query: "",
    },
    methods: {
        close() {
            this.inputOpen = false;
            this.$refs.input.blur();
            this.query = "";
        },
        submit() {
            $("#siteSearch").submit();
        },
    },
    mounted() {
        document.addEventListener("click", (e) => {
            const form = this.$refs.form;

            if (this.inputOpen) {
                if (form.contains(e.target)) {
                    this.submit();
                } else {
                    $("body").removeClass("search-open");
                    this.close();
                }
            } else {
                if (form.contains(e.target)) {
                    $("body").addClass("search-open");
                    this.inputOpen = true;
                    this.$refs.input.focus();
                }
            }
        });
    },
});
