const impactFiles = document.querySelectorAll(".area-files.impact-template");

impactFiles.forEach((brick) => {
    new Vue({
        el: "#" + brick.id,
        delimiters: ["${", "}$"],
        name: "Render Files",
        data: { openItems: [] },
        methods: {
            readMore(item) {
                const index = this.openItems.indexOf(item);

                if (index > -1) {
                    this.openItems.splice(index, 1);
                } else {
                    this.openItems.push(item);
                }
            },
        },
    });
});
