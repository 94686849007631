window.addEventListener("scroll", () => {
    const header = document.querySelector("header");
    const threshold = window.scrollY > header.clientHeight;
    header.classList.toggle("scroll", threshold);
});

new Vue({
    el: "#language-selector",
    name: "Language Selector",
    data: {
        dropdown: false,
    },
    methods: {
        openDropdown(el) {
            this.dropdown = !this.dropdown;
            if (this.dropdown) el.target.focus();
            else el.target.blur();
        },
    },
});
